import React, { useRef, useEffect } from "react";
import Swiper, { SwiperOptions } from "swiper";

import Slide from "./slide";

import "swiper/dist/css/swiper.css";
import "./gallery.scss";

export interface GalleryImage {
    desktopImage: string;
    mobileImage: string;
    thumbnail?: string;
    alt: string;
}

export interface GalleryProps {
    useBackgroundImage?: boolean;
    images: Array<GalleryImage>;
    themeColor: string;
    showNextPrev?: boolean;
    showPagination?: boolean;
    lazyLoad?: boolean;
    loop?: boolean;
}

const Gallery: React.FC<GalleryProps> = (props) => {
    const { images, lazyLoad, useBackgroundImage, loop } = props;

    const swiperEl = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let swiper: Swiper | null = null;
        if (swiperEl.current) {
            const swiperOptions: SwiperOptions = {
                preloadImages: !lazyLoad,
                centeredSlides: true,
                loop,
                keyboard: true
            };
            if (props.showNextPrev) {
                swiperOptions.navigation = {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
            if (props.showPagination) {
                swiperOptions.pagination = {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                }
            }
            if (props.lazyLoad) {
                swiperOptions.lazy = {
                    loadPrevNext: true,
                    loadPrevNextAmount: 1,
                    elementClass: "swiper-lazy",
                    loadingClass: "swiper-lazy-preloader",
                    loadedClass: "swiper-lazy-loaded"
                }
            }

            swiper = new Swiper(swiperEl.current, swiperOptions);
        }
        return () => {
            if (swiper) {
                swiper.destroy(true, true);
            }
        }
    }, [])

    return (
        <div ref={swiperEl} className="swiper-container gallery-top">
            <div className="swiper-wrapper">
                {
                    images.map((image, i) => {
                        if (useBackgroundImage) {
                            return (<Slide key={i} lazyLoad={lazyLoad} backgroundImage={image.desktopImage} alt={image.alt} />);
                        }
                        else {
                            return (<Slide key={i} lazyLoad={lazyLoad} src={image.mobileImage} srcSet={`${image.mobileImage} 640w, ${image.desktopImage} 1024w`} alt={image.alt} />)
                        }
                    })
                }
            </div>
            {
                props.showPagination &&
                <div className="swiper-pagination"></div>
            }
            {
                props.showNextPrev &&
                (
                    <React.Fragment>
                        <div className="swiper-button-next swiper-button-theme">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
                                <path d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z" fill={props.themeColor} />
                            </svg>
                        </div>
                        <div className="swiper-button-prev swiper-button-theme">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44">
                                <path d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z" fill={props.themeColor} />
                            </svg>
                        </div>
                    </React.Fragment>
                )
            }
        </div>
    );
}

Gallery.defaultProps = {
    themeColor: "#fff"
};

export default Gallery;