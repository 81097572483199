

export const triggerCustomEvent = (element: HTMLElement | Window, eventName: string, data?: any): CustomEvent => {
    let event: CustomEvent;
    if (typeof CustomEvent === "function") {
        event = new CustomEvent(eventName, { detail: data });
    } else {
        event = document.createEvent("CustomEvent");
        event.initCustomEvent(eventName, true, true, data);
    }
    element.dispatchEvent(event)
    return event;
}

export const getDataAttribute = (element: HTMLElement, key: string): any => {
    if (element.dataset) {
        return element.dataset[key];
    } else {
        return element.getAttribute(`data-${camelCaseToAttribute(key)}`);
    }
}

export const setDataAttribute = (element: HTMLElement, key: string, value: any) => {
    if (element.dataset) {
        element.dataset[key] = value;
    }
    else {
        element.setAttribute(`data-${camelCaseToAttribute(key)}`, value);
    }
}

export const setDataAttributes = (element: HTMLElement, keyValuePairs: Map<string, any>) => {
    keyValuePairs.forEach(([key, value], i) => {
        setDataAttribute(element, key, value);
    });
}

export const serializeElementData = (element: HTMLElement): DOMStringMap | Object => {
    if (element.dataset) {
        return element.dataset;
    } else {
        return attributesToDataObject(element.attributes);
    }
};

export const formatCurrency = (value: number, showCents: boolean = false): string => {
    const noDecimals = showCents ? 2 : 0;
    return value.toLocaleString("en-ZA", { style: "currency", currency: "ZAR", minimumFractionDigits: noDecimals, maximumFractionDigits: noDecimals, currencyDisplay: "symbol" })
};

export const formatPhoneNumbers = (phoneNumber: string): string => {
    if (phoneNumber) {
        if (phoneNumber.startsWith("+")) {
            const regex = /^\+([\d]{2})([\d]{2})([\d]{3})([\d]{4})/;
            return phoneNumber.replace(regex, "+$1 $2 $3 $4");
        }
        else if (phoneNumber.startsWith("0")) {
            const regex = /^0([\d]{2})([\d]{3})([\d]{4})/;
            return phoneNumber.replace(regex, "0$1 $2 $3");
        }
        else {
            return `0${phoneNumber}`;
        }
    }
    else {
        return phoneNumber;
    }
}


const attributesToDataObject = (attributes: NamedNodeMap) => {
    let elementData = {};
    for (var i = 0, j = attributes.length; i < j; i++) {
        if (attributes[i].name.startsWith("data-"))
            elementData[attributeToCamelCase(attributes[i].name.replace("data-", ""))] = attributes[i].value;
    }
    return elementData;
};

const camelCaseToAttribute = (camelCaseString: string): string => {
    let attrString = "";
    for (let i = 0, j = camelCaseString.length; i < j; i++) {
        let char = camelCaseString[i];
        if (char.toUpperCase() === char && char.toLowerCase() !== char) {
            if (i === 0) {
                attrString = char.toLowerCase();
            }
            else {
                attrString += `-${char.toLowerCase()}`;
            }
        }
        else {
            attrString += char;
        }
    }
    return attrString;
}

const attributeToCamelCase = (attributeString: string): string => {
    let camelCaseString = "", nextToUpper = false;
    for (let i = 0, j = attributeString.length; i < j; i++) {
        let char = attributeString[i];
        if (char === "-") {
            nextToUpper = true
        }
        else {
            if (!nextToUpper) {
                camelCaseString += char.toLowerCase();
            }
            else {
                camelCaseString += char.toUpperCase();
                nextToUpper = false;
            }
        }
    }
    return camelCaseString;
}

