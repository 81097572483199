import React from "react";
import ReactDOM from "react-dom";

import Gallery, { GalleryImage } from "../components/listing/gallery/gallery";
import { IListingContext, IAjaxResponseBase } from "../utils/types";
import FormPageContextProvider, { IFormValues } from "../components/forms/context-provider";
import ContactForm from "../components/forms/contact-form";
import ParagraphExpander from "../components/listing/paragraph-expander";

import "./listing-page.scss";
import "../components/page-elements/social-media-share-links.scss";
import { postListingLeadForm } from '../server-api/contact-us';
import initializeAgentContactCards from '../components/listing/agent-contact-cards/agent-contact-cards';


const initListingPage = (listingContext: IListingContext | undefined) => {
    const gallery = document.getElementById("gallery");
    if (listingContext) {
        const { galleryImages, themeColor } = listingContext;
        if (galleryImages) {
            const images: Array<GalleryImage> = galleryImages.map(responsiveImage => {
                return {
                    desktopImage: responsiveImage.desktopUrl,
                    mobileImage: responsiveImage.mobileUrl,
                    alt: "",
                };
            });

            if (gallery) {
                ReactDOM.render(<Gallery
                    images={images}
                    loop
                    lazyLoad
                    showNextPrev
                    showPagination
                    themeColor={themeColor}
                />, gallery);
            }
        }

        const agentContactSlider = document.getElementById("agentContactSlider");
        const contactInfoContainer = document.getElementById("contactInfoContainer");

        if (agentContactSlider && contactInfoContainer) {
            initializeAgentContactCards(agentContactSlider, contactInfoContainer, listingContext.agentIds);
        }

        const contactForm = document.getElementById("contactForm");
        if (contactForm) {
            ReactDOM.render(
                <FormPageContextProvider initialValues={{ email: "", message: "", name: "", phone: "" }} onSubmit={(values) => handleFormSubmit(values, listingContext.listingId)}>
                    <ContactForm />
                </FormPageContextProvider>, contactForm)
        }
    }

    const expander = document.querySelector<HTMLDivElement>("#expander");
    if (expander) {
        ParagraphExpander(expander, {
            buttonSelector: "#readMore",
            paragraphSelector: "#desc",
        })
    }

    const backButton = document.querySelector<HTMLAnchorElement>(".breadcrumbs_item--back");
    if (backButton) {
        const resultsPageRegex = /\/(residential|commercial|farms)\/(sales|rentals)\/?$/;
        const agentPageRegex = /\/agentprofile\/.*\/(\d+)$/;

        backButton.onclick = (e: MouseEvent) => {
            if (document.referrer) {
                const url = document.referrer.split("?")[0];

                if (resultsPageRegex.test(url)) {
                    e.preventDefault();
                    history.back();
                }

                if (agentPageRegex.test(url)) {
                    e.preventDefault();
                    history.back();
                }
            }
        }
    }

}

const handleFormSubmit = ({ email, message, name, phone }: IFormValues, listingId: number): Promise<IAjaxResponseBase> => {
    return postListingLeadForm(listingId, name, phone, email, message);
}

export default initListingPage;