export const parseQueryStringArray = (parameterValue: string | string[] | null | undefined): string[] => {
    if (parameterValue) {
        if (typeof parameterValue === "string") {
            return [parameterValue];
        }
        else {
            return parameterValue;
        }
    }
    return [];
}